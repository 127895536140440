<template>
  <v-container>
    <v-row v-if="showButtonAddNewCard && !desactiveFields">
      <v-col :cols="12">
        <v-card-text>
          <v-btn color="primary" @click="clickAddNewCard()">Adicionar card</v-btn>
        </v-card-text>
      </v-col>
    </v-row>
    <v-row>
      <v-col :cols="cols" v-for="(packageSelected, index) in packagesSelected" :key="index">
        <v-card class="mx-auto">
          <v-card-title class="primary white--text" :title="`${packageSelected.ddd.code} - ${packageSelected.pessoa.nome_abreviado}`">
            <div class="title-content">
              <span v-if="packageSelected.ddd.code">({{ packageSelected.ddd.code }})</span> -
              <span style="overflow: hidden;">{{ packageSelected.pessoa.nome_abreviado }}</span>
            </div>
            <v-btn icon class="close-button" v-if="!desactiveFields" @click="clickRemoveCard(index)">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-actions>
            <v-row>
              <v-col cols="12">
                <v-autocomplete
                  dense
                  outlined
                  required
                  label="DDD"
                  v-model="packageSelected.ddd"
                  :items="ddd.data"
                  item-text="code"
                  return-object
                  :rules="dddRules"
                  :loading="loadingData"
                  @change="updateValues"
                  :readonly="desactiveFields"
                >
                  <template v-slot:selection="data">
                    {{ data.item.code }} - {{ data.item.region }}
                  </template>
                  <template v-slot:item="data">
                    <template v-if="typeof data.item !== 'object'">
                      <v-list-item-content
                        v-text="data.item"
                      ></v-list-item-content>
                    </template>
                    <template v-else>
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="data.item.code"
                        ></v-list-item-title>
                        <v-list-item-subtitle
                          v-html="data.item.region"
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>

                <PersonField
                  v-if="showAllFields"
                  :selectedPerson="packageSelected.pessoa"
                  :label="'Selecione a Pessoa'"
                  :readonly="desactiveFields"
                  @save="saveDataComponent($event, index, 'pessoa')"
                ></PersonField>

                <iccid-field
                  v-if="showFieldIccid"
                  v-model="packageSelected.iccid"
                  :ddd="packageSelected.ddd.code"
                  :rules="objectRule"
                  :chipSelected="packageSelected.iccid"
                  :readOnlyField="desactiveFields"
                  @changeData="changeDataIccid($event, index)"
                ></iccid-field>

                <AccountField
                  v-if="showFieldConta"
                  :selectedAccount="packageSelected.conta"
                  :rules="objectRule"
                  :readonly="desactiveFields"
                  @saveAccount="saveDataComponent($event, index, 'conta')"
                ></AccountField>

                <DeviceField
                  v-if="showFieldAparelho"
                  :selectedDevice="packageSelected.aparelho"
                  :readonly="desactiveFields"
                  @save="saveDataComponent($event, index, 'aparelho')"
                ></DeviceField>

                <LineReference
                  v-if="showFieldConta"
                  :selectedLine="packageSelected.linha_referencia"
                  :readonly="desactiveFields"
                  @save="saveDataComponent($event, index, 'linha_referencia')"
                ></LineReference>

                <v-autocomplete
                  dense
                  outlined
                  required
                  label="Selecione o tipo do pacote"
                  :items="packageTypes.data"
                  item-text="label"
                  item-value="id"
                  return-object
                  @change="clickPackageType($event, index)"
                  :disabled="false"
                  :loading="loadingData"
                  v-if="!desactiveFields"
                >
                </v-autocomplete>

                <v-autocomplete
                  dense
                  outlined
                  required
                  label="Selecione um pacote"
                  v-model="selectedPackage"
                  :items="listPackages[index]"
                  item-text="descricao"
                  item-value="id"
                  return-object
                  :filter="customFilterPacote"
                  @change="clickPackage($event, index)"
                  :rules="[Object.keys(packageSelected.packages).length > 0 || 'Obrigatório']"
                  :loading="loadingPackages[index].value"
                  v-if="!desactiveFields"
                >
                </v-autocomplete>

                <v-text-field
                  v-if="page === 'interaction'"
                  v-model="packageSelected.linha_ativar.value"
                  @change="onChangeFone(index)"
                  label="Linha Ativar"
                  outlined
                  dense
                  v-mask="mask"
                  :rules="telephoneRules[index]"
                />

                <SuspendLineDateFields
                  v-if="default_date !== '' && default_hour !== ''"
                  :type="''"
                  :index="index"
                  :default_label_date="'Data de Ativação'"
                  :default_label_hour="'Hora de Ativação'"
                  :default_date="default_date"
                  :default_hour="default_hour"
                  @changeDateValues="updateDate($event, index)"
                />
              </v-col>
            </v-row>
          </v-card-actions>

          <v-divider></v-divider>

          <v-card-title class="primary white--text">
            <div class="title-content">
              Pacotes
            </div>
          </v-card-title>

          <v-card-text>
            <v-list-item-group >
              <v-list-item v-for="(item, indexPackage) in packageSelected.packages" :key="indexPackage">
                <v-list-item-title :title="item.descricao">
                  {{item.descricao}}
                </v-list-item-title>
                <v-btn icon v-if="!desactiveFields" @click="removePackageSelected(index, indexPackage)">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-list-item>
            </v-list-item-group>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import IccidField  from "@/components/fields/IccidField.vue";
import SuspendLineDateFields from "@/components/customFieldsComponents/SuspendLineDateFields.vue";
import PersonField from "@/components/fields/PersonField.vue";
import AccountField from "@/components/fields/AccountField.vue";
import DeviceField from "@/components/fields/DeviceField.vue";
import LineReference from "@/components/fields/LineReference.vue";
import isEmpty from "lodash/isEmpty";

export default {
  components: {
    LineReference,
    SuspendLineDateFields,
    IccidField,
    DeviceField,
    AccountField,
    PersonField
  },
  props: {
    cols: {
      type: Number,
      default: 4
    },
    options: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: "Escolha uma opção"
    },
    page: {
      type: String,
      default: '',
    },
    desactiveFields: {
      type: Boolean,
      default: false,
    },
    showButtonAddNewCard: {
      type: Boolean,
      default: true,
    },
    showFieldIccid: {
      type: Boolean,
      default: false,
    },
    showFieldConta: {
      type: Boolean,
      default: false,
    },
    showFieldAparelho: {
      type: Boolean,
      default: false,
    },
    showAllFields: {
      type: Boolean,
      default: false
    },
    default_date: {
      type: String,
      default: '',
    },
    default_hour: {
      type: String,
      default: '',
    },
    selectedPackages: {
      type: Array,
      default: () => [],
    },
    uniqueKeyStorage: {
      type: String,
      default: '',
    }
  },
  data() {
    return {
      selectedOption: null,
      loadingPackages: [],
      objectRule: [v => (v && Object.keys(v).length > 0) || 'Obrigatório'],
      listAccounts: [],
      imeis: [],
      selectedPackage: null,
      telephoneRules: [],
      mask: ["(",/\d/,/\d/,") ",/\d/,/\d/,/\d/,/\d/,/\d/,"-",/\d/,/\d/,/\d/,/\d/],
      dddRules: [v => (v && Object.keys(v).length > 0) || 'Obrigatório'],
      listPackages: [],
      packagesSelected: [],
      loadingData: true,
    };
  },
  async mounted() {
    try {
      if (this.page !== 'wizard') {
        this.packagesSelected = [...this.options];
        this.packagesSelected.forEach((item, index) => {
          this.loadingPackages.push({value: false});
        });
      } else {
        const dataLocalStorage = await this.loadDataLocalStorage(this.uniqueKeyStorage);
        if (!isEmpty(dataLocalStorage.data) && dataLocalStorage.type === 'package' && this.page === 'wizard') {
          this.packagesSelected = dataLocalStorage.data;
          this.packagesSelected.forEach((item, index) => {
            this.loadingPackages.push({value: false});
          });
        } else {
          await this.clickAddNewCard();
        }
      }

      await Promise.all([
        this.loadDDD(),
        this.loadPersons(),
        this.loadingFilters(),
        this.loadAccounts(),
        this.loadImeis(),
      ]);
      this.imeis = this.getImeis();
      if (this.showAllFields) {
        this.showFieldIccid = true;
        this.showFieldAparelho = true;
        this.showFieldConta = true;
      }
      this.loadingData = false;
    } catch (error) {
      await this.$swal("Oops...", error.message, "error");
    }
  },
  computed: {
    ...mapState("persons", ["persons"]),
    ...mapState("plans", ["ddd"]),
    ...mapState("packages", ["packageTypes","packages"]),
  },
  methods: {
    ...mapActions("plans", ["loadDDD"]),
    ...mapActions("persons", ["loadPersons"]),
    ...mapActions("packages", ["loadPackages","loadingFilters"]),
    ...mapActions("solicitation", ["loadAccountsJoin","loadImeis","loadDataLocalStorage"]),
    ...mapGetters("solicitation", ["getAccounts","getImeis"]),

    saveDataComponent(data, index, component) {
      this.packagesSelected[index][component] = data;
      this.updateValues();
    },

    async updateDate(selectedValue, index) {
      let hourValue = {
        type: "initHour",
        value: selectedValue.finishHour
      };

      let dateValue = {
        type: "initDate",
        value: selectedValue.initialDate
      };

      this.packagesSelected[index]['initDate'] = dateValue;
      this.packagesSelected[index]['initHour'] = hourValue;
      this.validateDate(selectedValue);

      await this.updateValues();
    },

    validateDate(selectedValue) {
      const today = new Date();
      const selectedDate = selectedValue.initialDate;

      let formatedSelectedDate = new Date(selectedDate);
      formatedSelectedDate.setHours(formatedSelectedDate.getHours() + 12);

      let formatedToday = today.toLocaleDateString("pt-BR", {
        timeZone: "UTC"
      });

      if (today >= formatedSelectedDate) {
        this.saveValid = true;
        this.setSave(this.saveValid);
      } else {
        this.$swal(
          "Oops..",
          `A data não pode ser maior que ${formatedToday}, mude a data para salvar!`,
          "error"
        );
        this.saveValid = false;
        this.setSave(this.saveValid);
      }
    },

    onChangeFone: async function (index) {
      this.telephoneRules[index] = [
        v =>
          /^\([0-9]{2}\) [0-9]?[0-9]{4}-[0-9]{4}$/.test(v) ||
          "Numero de telefone invalido"
      ];

      await this.updateValues();
    },

    async removePackageSelected(index, indexPackage) {
      this.packagesSelected[index].packages.splice(indexPackage, 1);
      await this.updateValues();
    },

    async clickPackage(dataPackage, index) {
      this.packagesSelected[index].packages.push(dataPackage);
      this.$nextTick(() => {
        this.selectedPackage = null;
      });
      await this.updateValues();
    },

    loadAccounts: async function () {
      this.loading = true;
      await this.loadAccountsJoin().catch(error => {
        this.$swal("Oops...", error.message, "error");
      });
      this.listAccounts = this.getAccounts();
      this.loading = false;
    },

    async changeDataIccid(data, index) {
      this.packagesSelected[index].iccid = data;
      await this.updateValues();
    },

    async updateValues() {
      if (!this.desactiveFields || this.page === 'interaction') {
        this.$emit("updateValues", this.packagesSelected);
      }
    },

    async clickAddNewCard() {
      this.listPackages.push();
      this.loadingPackages.push({value: false});
      this.packagesSelected.push({
        aparelho: {},
        conta: {},
        ddd: {},
        iccid: {},
        linha_referencia: {},
        pessoa: {},
        packages: [],
      });
    },

    async clickRemoveCard(index) {
      this.packagesSelected.splice(index, 1);
      this.listPackages.splice(index, 1);
      this.loadingPackages.splice(index, 1);
      await this.updateValues();
    },

    async clickPackageType(packageType, index) {
      this.loadingPackages[index].value = true;
      this.showPackages = true;
      await this.loadPackages({ package_type: packageType.id }).catch(error => {
        this.$swal("Oops...", error.message, "error");
      });
      this.listPackages[index] = this.packages.data;
      this.loadingPackages[index].value = false;
    },

    customFilterPacote(item, queryText) {
      const regExp = new RegExp(queryText, 'i')
      const descricao = item.descricao;
      return (
        regExp.test(descricao)
      );
    },
  }

}
</script>
<style>
.title-content {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.close-button {
  position: absolute;
  top: 0;
  right: 0;
}
</style>