<template>
  <v-row class="mx-auto">
    <v-col
      v-for="(pacote, index) in field.data"
      :key="index"
      class="col-sm-12 col-md-6"
    >
      <v-card class="mx-auto">
        <v-card-title class="primary white--text" :title="index">
          {{ pacote.descricao }}
        </v-card-title>
        <v-card-text>
          <v-col class="col-sm-12 col-md-12 col-lg-12">
            <div v-if="loading">
              <v-skeleton-loader type="heading"></v-skeleton-loader>
            </div>
            <LineComponent
              v-if="!loading"
              :selectedLine="pacote.line"
              :readonly="true"
            ></LineComponent>

            <div class="col-md-12" v-if="loading">
              <v-alert
                  border="left"
                  class="text-center"
                  dense
                  type="warning"
                  dark
              >Carregando pacotes da linha!
              </v-alert>
            </div>
            <div class="d-flex">
              <div class="col-md-6">
                <template v-if="pacote.packages && !loading">
                  <v-alert border="left" color="indigo" dark dense class="mb-0">
                    Pacotes da linha:
                  </v-alert>
                  <v-list>
                    <template v-for="(linePackage, index) in pacote.packages">
                      <v-list-item :key="linePackage.nome">
                        <v-list-item-content>
                          <v-list-item-title :title="linePackage.nome">
                            {{ linePackage.nome }}
                            <v-list-item-subtitle>
                              R$ {{ linePackage.valor_assinatura}}
                            </v-list-item-subtitle>
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider
                          v-if="index < pacote.packages.length - 1"
                          :key="index"
                      ></v-divider>
                    </template>
                  </v-list>
                </template>
              </div>

              <div class="col-md-6" v-if="pacote.linha_referencia && !loading">
                <template>
                  <v-alert border="left" color="indigo" dark dense class="mb-0">
                    Linha referência:
                  </v-alert>
                  <v-list>
                    <LineReferenceComponent
                        :label="'Usuário'"
                        :label_value="pacote.linha_referencia.nome_completo ? pacote.linha_referencia.nome_completo : 'Não existe'"
                    />
                    <v-divider />
                    <LineReferenceComponent
                        :label="'Linha'"
                        :label_value="pacote.linha_referencia.ddd ? '('+pacote.linha_referencia.ddd+') '+ pacote.linha_referencia.nr_linha : 'Não existe'"
                    />
                    <v-divider />
                    <LineReferenceComponent
                        :label="'Valor'"
                        :label_value="pacote.linha_referencia.valor_assinatura ? 'R$ '+pacote.linha_referencia.valor_assinatura : 'Não existe'"
                    />
                    <v-divider />
                  </v-list>
                </template>
              </div>
            </div>
          </v-col>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapState } from "vuex";
import LineReferenceComponent from "../../validate_forms/LineReferenceComponent.vue";
import LineComponent from "@/components/forms/LineComponent.vue";
export default {
  components: { LineComponent, LineReferenceComponent},
  props: ["field", "page"],
  data() {
    return {
      loading: true
    };
  },
  async mounted() {
    this.loading = false;
  },
  computed: {
    ...mapState("packages", ["lines"])
  },
  methods: { }
};
</script>
<style scoped>
.v-card__text,
.v-card__title {
  word-break: normal; /* maybe !important  */
}
</style>
