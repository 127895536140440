<template>
  <v-card outlined>
    <v-row class="mx-auto">
      <v-col cols="12">
        <v-card outlined :loading="loading" :disabled="loading" v-if="page !== 'approval' && page !== 'interaction'">
          <v-card-title class="subtitle-1">
            Selecione um ou mais pacotes
          </v-card-title>
          <v-card-text>
            <v-col cols="3" class="mb-n5">
              <v-autocomplete
                dense
                outlined
                required
                label="Selecione o tipo do pacote"
                :items="packageTypes"
                item-text="label"
                item-value="id"
                return-object
                @change="clickPackageType($event)"
                :loading="loading"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="6" v-if="showPackages" class="mb-n5">
              <v-autocomplete
                dense
                outlined
                required
                v-model="selectedPackage"
                label="Selecione um pacote"
                :items="packages"
                item-text="descricao"
                item-value="id"
                return-object
                :filter="customFilterPacote"
                @change="clickPackage($event)"
                :loading="loadingChips"
              >
              </v-autocomplete>
            </v-col>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        v-for="(pacote, index) in packagesSelect.lines"
        :key="index"
        class="col-sm-12 col-md-6"
      >
        <v-card class="mx-auto">
          <v-card-title class="primary white--text" :title="pacote.descricao">
            <div class="headerClass">
              {{ pacote.descricao }}
            </div>
            <v-spacer></v-spacer>
            <v-btn
              v-if="page !== 'approval' && page !== 'interaction' && packagesSelect.lines.length > 1"
              icon
              dark
              @click="closePackage(index)"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-col class="col-sm-12 col-md-12 col-lg-12">
              <LineComponent
                :selectedLine="pacote.line"
                :rules="[v => (v && Object.keys(v).length > 0) || 'Obrigatório']"
                :readonly="page == 'approval' || page == 'interaction' "
                @save="
                  pacote.line = $event;
                  changeLinePackages($event, pacote, index);
                "
              ></LineComponent>

              <v-autocomplete
                item-text="label"
                item-value="value"
                :items="packagesSelect.list_prazo_utilizacao"
                v-model="pacote.prazo_utilizacao"
                outlined
                dense
                label="Prazo de Utilização"
                :rules="requiredPrazo"
                @change="saveSelected()"
                v-if="page == 'interaction'"
              ></v-autocomplete>

              <DatePicker
                  v-if="page === 'interaction'"
                  v-model="pacote.initialDate"
                  value-type="YYYY-MM-DD"
                  format="DD/MM/YYYY"
                  :placeholder="'Data Ativação'"
                  style="width: 100%"
                  :title="'Data Ativação'"
                  @change="saveSelected()"
              ></DatePicker>
            </v-col>

            <v-divider></v-divider>

            <v-row>
              <v-col class="col-sm-12 col-md-12 col-lg-12">
                <v-alert
                  border="left"
                  class="text-center"
                  dense
                  type="warning"
                  v-if="loadingPackages[index].value"
                  dark
                  >Carregando pacotes da linha!
                </v-alert>
                <template v-if="pacote.line.id_linha && !loadingPackages[index].value">
                  <v-alert border="left" color="indigo" dark dense class="mb-0">
                    Pacotes da linha:
                  </v-alert>
                  <v-list>
                    <template v-for="(linePackage, index) in pacote.packages">
                      <v-list-item :key="linePackage.nome">
                        <v-list-item-content>
                          <v-row class="align-center" dense>
                            <v-col md="8">
                              {{ linePackage.nome }}
                            </v-col>
                          </v-row>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider
                        v-if="index < pacote.packages.length - 1"
                        :key="index"
                      ></v-divider>
                    </template>
                  </v-list>
                </template>
                <template v-if="!loadingPackages[index].value && pacote.line.id_linha && pacote.packages.length < 1">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        Não possui
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import {mapGetters, mapActions, mapState, mapMutations} from "vuex";
import axios from "@/axios.config";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/pt-br";
import Vue from "vue";
import LineComponent from "@/components/forms/LineComponent.vue";

export default {
  components: { LineComponent, DatePicker },
  props: ["field", "page"],
  data() {
    return {
      packagesSelect: [],
      loading: true,
      loadingChips: false,
      selectedPackage: [],
      showPackages: false,
      loadingPackages: [],
      required: [v => !!v || `O campo ${this.field.label} é obrigarório`],
      requiredPrazo: [v => !!v || `O campo Prazo de Utilização é obrigarório`]
    };
  },
  async mounted() {
    this.$emit('componentLoading', true);
    this.packagesSelect = this.field;
    this.packagesSelect.lines.map(val => {
      this.loadingPackages.push({
        value: false
      });
    });
    if (this.field.name === "roaming") {
      await this.loadingFilters({
        params: {
          type: "roaming"
        }
      }).catch(error => {
        this.$swal("Oops...", error.message, "error");
      });
    } else {
      await this.loadingFilters().catch(error => {
        this.$swal("Oops...", error.message, "error");
      });
    }

    this.saveSelected();
    this.loading = false;
    this.$emit('componentLoading', false);
  },
  computed: {
    ...mapState("solicitation", ["solicitation_validate_packages_values","solicitation_validate_values"]),
    ...mapGetters({
      name: "forms/getItemName",
      linePackages: "packages/getLinePackages",
      lines: "packages/getLines",
      packages: "packages/getPackages",
      allPackages: "packages/getAllPackages",
      packageTypes: "packages/getPackageTypes"
    })
  },
  methods: {
    ...mapActions("solicitation", ["setAutomationComponentValues"]),
    ...mapActions("interaction", ["setAutomationComponentFields"]),
    ...mapMutations("forms", ["SET_FORM_EMAIL"]),
    ...mapActions("packages", [
      "loadingFilters",
      "loadPackages",
      "loadAllPackages",
      "loadLinePackages",
    ]),
    clickPackage(pacote) {
      Vue.config.warnHandler = function (msg, vm, trace) {
        // Faz nada para ignorar o aviso "Duplicate keys detected"
      };
      const newPackage = {
        valor: pacote.valor_assinatura,
        tipo_assinatura: pacote.tipo_assinatura,
        id_historico_assinatura: pacote.id_historico_assinatura,
        descricao: pacote.descricao,
        line: {},
        packages: []
      };

      this.loadingPackages.push({
        value: false
      });

      this.selectedPackage = [];
      this.packagesSelect.lines.push(newPackage);
    },
    async changeLinePackages(line, pacoteSelected, index) {
      this.loading = true;
      this.loadingPackages[index].value = true;
      if (line.id_linha) {
        pacoteSelected.packages = [];
        await this.loadLinePackages({ id_linha: line.id_linha }).catch(error => {
          this.$swal("Oops...", error.message, "error");
        });
        pacoteSelected.packages = this.linePackages.data;

        //Atualiza store
        this.saveSelected();
      }
      this.loading = false;
      this.loadingPackages[index].value = false;
    },
    saveSelected() {
      if (this.page !== 'interaction') {
        let values_fields = {...this.solicitation_validate_values};
        values_fields['roaming'] = this.packagesSelect;
        this.setAutomationComponentValues({
          automation: 'roaming',
          data: values_fields
        });
        this.SET_FORM_EMAIL({});
        this.SET_FORM_EMAIL(this.solicitation_validate_values);
      } else {
        this.setAutomationComponentFields({
          automation: 'roaming',
          data: this.packagesSelect
        });
      }
    },
    async clickPackageType(packageType) {
      this.loadingChips = true;
      this.showPackages = true;
      try {
        await Promise.all([
          await this.loadPackages({ package_type: packageType.id })
        ]);
      } catch (error) {
        await this.$swal("Oops...", error.message, "error");
      }
      this.loadingChips = false;
    },
    closePackage(index) {
      if (index > -1) {
        //Remove do objeto para remover do frontend
        this.packagesSelect.lines.splice(index, 1);
        this.loadingPackages.splice(index, 1);
        //Atualiza store
        this.saveSelected();
      }
    },
    customFilter(item, queryText) {
      queryText = queryText.replace(/[\s()\-/]/g, '');
      const regExp = new RegExp(queryText, 'i')
      const phoneNumber = item.ddi + item.ddd + item.nr_linha;
      return (
          regExp.test(phoneNumber) || regExp.test(item.nome_completo.replace(/\s/g, ''))
      );
    },
    customFilterPacote(item, queryText) {
      const regExp = new RegExp(queryText, 'i')
      const descricao = item.descricao;
      return (
          regExp.test(descricao)
      );
    },
  }
};
</script>
<style scoped>
.v-card__text,
.v-card__title {
  word-break: normal; /* maybe !important  */
}
.headerClass {
  white-space: nowrap;
  word-break: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
