<template>
  <v-row class="mx-auto">
    <v-col cols="12" v-if="page !== 'approval' && page !== 'interaction'">
      <v-card outlined :loading="loading" :disabled="loading">
        <v-card-text>
          <v-btn color="primary" @click="clickAddNewLine()"
            >Selecionar Linha</v-btn
          >
        </v-card-text>
      </v-card>
    </v-col>
    <v-col
      v-for="(line, index) in field.lines"
      :key="index"
      md="6"
      sm="12"
    >
      <v-card outlined class="mx-auto">
        <v-card-title class="primary white--text">
          <div class="headerClass">
            {{line.line.ddi ? line.line.ddi+' ('+line.line.ddd+') '+line.line.nr_linha : 'Selecione uma linha' }}
          </div>
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="closeLine(index)"
            class="mx-3 mt-2"
            v-if="field.lines.length > 1 && page !== 'approval' && page !== 'interaction'"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <LineComponent
                :selectedLine="line.line"
                :rules="[v => (v && Object.keys(v).length > 0) || 'Obrigatório']"
                :readonly="line.readonly"
                @save="
                  line.line = $event;
                  changeLine($event, line, index);
                "
              ></LineComponent>
              <v-select
                v-if="page === 'interaction' && !loadingSelect"
                :items="field.options_situation"
                item-text="label"
                item-value="option"
                label="Informar Situação"
                v-model="line.situation"
                return-object
                dense
                outlined
                :rules="[v => (v && Object.keys(v).length > 0) || 'Obrigatório']"
              ></v-select>
              <v-autocomplete
                v-if="page === 'interaction' && !loadingSelect && line.situation && line.situation.value === 3"
                dense
                outlined
                required
                label="Tipo Baixa"
                v-model="line.tipo_baixa"
                :items="field.tipo_baixa_aparelho"
                item-text="name"
                item-value="value"
                return-object
              >
              </v-autocomplete>
              <SuspendLineDateFields
                v-if="!loading && page === 'interaction'"
                :type="field.name"
                :index="index"
                :default_hour="field.default_hour"
                :default_date="field.default_date"
                @changeDateValues="updateDate"
              />
            </v-col>
            <ListPackage
              v-if="loadingLinePackages[index]"
              :loadingPackage="loadingLinePackages[index].value"
              :lineSelected="line"
            ></ListPackage>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import SuspendLineDateFields from "./SuspendLineDateFields.vue";
import ListPackage from "@/components/package/ListPackage";
import LineComponent from "@/components/forms/LineComponent.vue";

export default {
  components: { LineComponent, ListPackage, SuspendLineDateFields },
  props: ["field", "page"],
  data() {
    return {
      suspendLine: [],
      loading: true,
      loadingSelect: true,
      loadingLinePackages : [],
      required: [v => !!v || "Linha é obrigatório"],
      show: false,
      lineRules: [v => !!v || "Linha é obrigatório"],
    };
  },
  async mounted() {
    this.$emit('componentLoading', true);
    let params = {};
    if (this.page === 'approval') {
      params = { showAll: true }
    }

    this.field.lines.map(val => {
      this.loadingLinePackages.push({ value: false });
    });
    //Salva os pacotes já no storage para enviar caso não tenha sido editado nenhum dado
    this.setValuesStore();
    this.loading = false;
    this.loadingSelect = false;
    this.$emit('componentLoading', false);
  },
  computed: {
    ...mapState("packages", ["lines"]),
    ...mapState("solicitation", ["solicitation_validate_values"]),
    ...mapGetters({
      linePackages: "packages/getLinePackages"
    })
  },
  methods: {
    ...mapActions("packages", ["loadLinePackages"]),
    ...mapMutations("forms", ["SET_FORM_EMAIL"]),
    ...mapActions("interaction", ["setAutomationComponentFields"]),
    ...mapActions("solicitation", [
      "setVoluntaryLineSuspension",
      "updateSolicitationValidateSuspendLine",
      "setAutomationComponentValues"
    ]),

    setValuesStore() {
      if (this.page !== 'interaction') {
        let values_fields = {...this.solicitation_validate_values};
        values_fields[this.field.name] = this.field;
        this.setAutomationComponentValues({
          automation: this.field.name,
          data: values_fields
        });
        this.SET_FORM_EMAIL({});
        this.SET_FORM_EMAIL(this.solicitation_validate_values);
      } else {
        this.setAutomationComponentFields({
          automation: this.field.name,
          data: this.field
        });
      }
    },

    async clickAddNewLine() {
      let newLine = {
        packages: [],
        line: {}
      };
      this.field.lines.push(newLine);
      this.loadingLinePackages.push({ value: false });
    },

    async changeLine(line, lineSelected, index) {
      this.loadingLinePackages[index].value = true;
      if (line.id_linha) {
        lineSelected.packages = [];
        await this.loadLinePackages({
          id_linha: line.id_linha,
        }).catch(error => {
          this.$swal("Oops...", error.message, "error");
        });
        lineSelected.packages = this.linePackages.data;
      }
      this.setValuesStore();
      this.loadingLinePackages[index].value = false;
    },

    updateDate(data) {
      this.field.lines[data.index].finish_date = data.initialDate;
      this.field.lines[data.index].finish_hour = data.finishHour;
      this.field.lines[data.index].date_prev_reactivation = data.finishDate;
      this.setValuesStore();
    },

    closeLine(index) {
      this.field.lines.splice(index, 1);
      this.loadingLinePackages.splice(index, 1);
      this.setValuesStore();
    },

    customFilter(item, queryText) {
      queryText = queryText.replace(/[\s()\-/]/g, '');
      const regExp = new RegExp(queryText, 'i')
      const phoneNumber = item.ddi + item.ddd + item.nr_linha;
      return (
          regExp.test(phoneNumber) || regExp.test(item.nome_completo.replace(/\s/g, ''))
      );
    },
  }
};
</script>
<style scoped>
.headerClass {
  white-space: nowrap;
  word-break: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
