<template>
  <v-row class="mx-auto">
    <v-col cols="12" v-if="page !== 'approval' && page !== 'interaction'">
      <v-card outlined :loading="loading" :disabled="loading">
        <v-card-title class="subtitle-1">Cancelar Linhas</v-card-title>
        <v-card-text>
          <v-btn color="primary" @click="clickAddNewLine()"
            >Selecionar Linha</v-btn
          >
        </v-card-text>
      </v-card>
    </v-col>
    <v-col
      v-for="(lineSelected, index) in lineSelect"
      :key="index"
      sm="6"
    >
      <v-card outlined class="mx-auto" :loading="!loading">
        <v-card-title class="primary white--text">
          <div class="headerClass" >
            {{lineSelected && lineSelected.ddi ? lineSelected.ddi+' ('+lineSelected.ddd+') '+lineSelected.nr_linha : 'Selecione uma linha' }}
          </div>
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="closeLine(index)"
            class="mx-3 mt-2"
            v-if="lineSelect.length > 1 && page !== 'approval' && page !== 'interaction'"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" :loading="loading">
              <LineComponent
                :selectedLine="lineSelected"
                :rules="[v => (v && Object.keys(v).length > 0) || 'Obrigatório']"
                :readonly="page == 'approval' || page == 'interaction'"
                :loading="loading"
                @save="
                  lineSelected = $event;
                  updateSelectedLine($event, index)
                "
              ></LineComponent>

              <v-autocomplete
                v-if="page === 'interaction'"
                dense
                outlined
                required
                label="Aparelho"
                v-model="lineSelected.aparelho"
                :items="lineSelected.listCondtionAparelho"
                item-text="name"
                :rules="aparelhoRules"
                return-object
                @change="updateValues"
              >
              </v-autocomplete>

              <v-autocomplete
                v-if="page === 'interaction' && lineSelected.aparelho && lineSelected.aparelho.value == 5"
                dense
                outlined
                required
                label="Tipo Baixa"
                v-model="lineSelected.tipo_baixa"
                :items="lineSelected.tipoBaixaAparelho"
                :rules="[v => !!v || 'Obrigatório']"
                item-text="name"
                item-value="value"
                @change="updateValues"
              >
              </v-autocomplete>

              <SuspendLineDateFields
                v-if="!loading && page === 'interaction'"
                :field="field"
                :index="index"
                :default_date="field.default_date"
                :default_hour="field.default_hour"
                @changeDateValues="updateDate"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapState, mapGetters, mapMutations } from "vuex";
import SuspendLineDateFields from "./SuspendLineDateFields.vue";
import LineComponent from "@/components/forms/LineComponent.vue";

export default {
  components: { LineComponent, SuspendLineDateFields },
  props: ["field", "page"],
  data() {
    return {
      lineSelect: [],
      loading: true,
      lineRules: [v => !!v || "Linha é obrigatório"],
      aparelhoRules: [v => !!v || "Aparelho é obrigatório"],
    };
  },
  async mounted() {
    this.$emit('componentLoading', true);
    this.lineSelect = this.field.lines;

    this.loading = false;
    await this.updateValues();
    this.$emit('componentLoading', false);
  },
  computed: {
    ...mapState("packages", ["lines"]),
    ...mapState("solicitation", ["solicitation_validate_values"]),
    ...mapGetters({
      linePackages: "packages/getLinePackages"
    })
  },
  methods: {
    ...mapActions("packages", ["loadLinePackages"]),
    ...mapMutations("forms", ["SET_FORM_EMAIL"]),
    ...mapActions("interaction", ['setAutomationComponentFields']),
    ...mapActions("solicitation", [
      "setCancelLineComponentValues",
      "setAutomationComponentValues"
    ]),
    ...mapMutations({ setModel: "forms/setModelCancelLine" }),
    async clickAddNewLine() {
      this.lineSelect = [...this.lineSelect, {}];
    },
    async updateSelectedLine(lineSelected, index) {
      this.loading = true;
      this.lineSelect[index] = lineSelected;
      // this.lineSelect[index] = {
      //   value: lineSelected.id_linha,
      //   id_pessoa: lineSelected.id_pessoa,
      //   ddd: lineSelected.ddd,
      //   ddi: lineSelected.ddi,
      //   nr_linha: lineSelected.nr_linha,
      //   nome_completo: lineSelected.nome_completo,
      //   aparelho: lineSelected.aparelho,
      //   tipo_baixa: lineSelected.tipo_baixa,
      //   listCondtionAparelho: lineSelected.listCondtionAparelho,
      // };
      await this.updateValues();
      this.loading = false;
    },

    async updateValues() {
      let cancelLineList = [];
      // this.lineSelect.map(line => {
      //   let newLine = {
      //     name: "line",
      //     type: "line",
      //     label: "Linha",
      //     value: line.value,
      //     ddd: line.ddd,
      //     ddi: line.ddi,
      //     nr_linha: line.nr_linha,
      //     id_pessoa: line.id_pessoa,
      //     nome_completo: line.nome_completo,
      //     aparelho: line.aparelho,
      //     tipo_baixa: line.tipo_baixa ? line.tipo_baixa : null,
      //   };
      //   cancelLineList.push(newLine);
      // });

      if (this.page !== 'interaction') {
        this.setAutomationComponentValues({
          automation: "cancel_line",
          data: { cancel_line: this.lineSelect }
        });
        this.SET_FORM_EMAIL({});
        this.SET_FORM_EMAIL(this.solicitation_validate_values);
      } else {
        await this.setAutomationComponentFields({
          automation: "cancel_line",
          data: this.lineSelect
        });
      }
    },
    closeLine(index) {
      this.loading = true;
      if (index > -1) {
        this.lineSelect.splice(index, 1);
        this.updateValues();
      }
      this.loading = false;
    },
    customFilter(item, queryText) {
      const textOne = item.nr_linha.toLowerCase();
      const textTwo = item.nome_completo.toLowerCase();
      const searchText = queryText.toLowerCase();

      return (
        textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1
      );
    },
    updateDate(data) {
      let fields = [
        {
          type: "initHour",
          value: data.finishHour
        },
        {
          type: "initDate",
          value: data.initialDate
        }
      ];

      this.lineSelect[data.index].fields = fields;
      this.setCancelLineComponentValues(this.lineSelect);
    }
  }
};
</script>
<style scoped>
.headerClass {
  white-space: nowrap;
  word-break: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
